@import '../../../../styles/colors';
@import '../../../../styles/typography';

.container {
    overflow-x: auto;
    margin: 1rem;
}

.thead {
    position: sticky;
    top: 0rem;
    background: $sand;
    z-index: 550;

    &.dark {
        background: $darktablebg;
    }
}

.theader th:first-child {
    border-radius: 8px 0 0 8px;
}

.theader th:last-child {
    border-radius: 0 8px 8px 0;
}

.theader th {
    font-family: $inter;
    color: $pineGreen !important;
    text-align: center;
    font-size: 11px;
    font-weight: 100;
    user-select: none;
    padding: 12px;
}

.theader th:hover {
    cursor: pointer;
}

.header {
    padding: 12px;
    text-align: left;
    border-bottom: 2px solid $khaki;
    cursor: pointer;
    user-select: none;

    &.dark {
        border-bottom: 2px solid $darkGreen;
        color: $sand;
    }
}

.cell {
    padding: 12px;
    border-bottom: 1px solid $khaki;
    text-align: left;

    &.dark {
        border-bottom: 1px solid $darkGreen;
        color: $sand;
    }
}

.warning {
    color: $warningError;
}

.normal {
    color: $forestGreen;
}

.detailRow {
    font-size: 0.9em;

    .cell {
        color: $label;
        padding: 8px 12px;
    }

    &.dark {
        .cell {
            color: $labelDark;
        }
    }
}

.expandButton {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0 4px;
    color: $label;

    &.dark {
        color: $labelDark;
    }
}

.mainRow {
    cursor: pointer;

    &.dark {
        background-color: $darktablebg;
    }
}

.label {
    font-weight: 500;
    color: $label;
    
    &.dark {
        color: $labelDark;
    }
}

.select, .input {
    width: 100%;
    padding: 6px 8px;
    border: 1px solid $khaki;
    border-radius: 4px;
    background-color: white;
    font-size: 0.9em;

    &:focus {
        outline: none;
        border-color: $forestGreen;
    }

    &.dark {
        background-color: $darktablebg;
        border-color: $darkGreen;
        color: $sand;

        &:focus {
            border-color: $sand;
        }

        &::placeholder {
            color: $labelDark;
        }
    }
}

.updateButton {
    padding: 6px 12px;
    background-color: $forestGreen;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9em;
    transition: background-color 0.2s;

    &:hover:not(:disabled) {
        background-color: darken($forestGreen, 5%);
    }

    &:disabled {
        background-color: $khaki;
        cursor: not-allowed;
    }

    &.dark {
        background-color: $darkGreen;

        &:hover:not(:disabled) {
            background-color: lighten($darkGreen, 5%);
        }

        &:disabled {
            background-color: darken($khaki, 20%);
        }
    }
}
