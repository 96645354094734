@import "../../styles/colors";
@import "../../styles/typography";
@import "../../styles/AllEntities.scss";

@mixin info-card($backgroundColor, $boxShadowColor) {
  background-color: $backgroundColor;
  border-radius: 15px;
  border-style: none;
  border-width: 2px;
  // z-index: 3;
  box-shadow: 1px 11px 25px $boxShadowColor;
  margin: 2rem auto;
  padding: 2rem 0;
  animation-name: infoCard;
  position: relative;
  animation-duration: .7s;
  // animation-timing-function: ease-in;
}

@keyframes infoCard {
  from {
    right: 70px;
    opacity: 0;
  }
  to {
    right: 0px;
    opacity: 1;
  }
}

.cardModel {
  @include info-card(white, grey);
}

.cardModelDark {
  @include info-card($darktablebg, $pineGreen);
}

.headerCard {
  @include info-card(white, grey);
  height: 25rem;
}

.headerCardDark {
  @include info-card($darktablebg, $pineGreen);
  height: 25rem;
  background: rgb(27,34,54);
  background: linear-gradient(270deg, rgba(27,34,54,1) 0%, rgba(27,34,54,1) 74%, rgba(61,84,79,0.3) 100%);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.dev/svgjs' width='1300' height='800' preserveAspectRatio='none' viewBox='0 0 550 650'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1036%26quot%3b)' fill='none'%3e%3cpath d='M18 500L518 0L728 0L228 500z' fill='url(%26quot%3b%23SvgjsLinearGradient1037%26quot%3b)'%3e%3c/path%3e%3cpath d='M393 500L-107 0L-327.5 0L172.5 500z' fill='url(%26quot%3b%23SvgjsLinearGradient1038%26quot%3b)'%3e%3c/path%3e%3cpath d='M85.01288761769547 500L400 185.01288761769547L400 500z' fill='url(%26quot%3b%23SvgjsLinearGradient1037%26quot%3b)'%3e%3c/path%3e%3cpath d='M0 500L314.98711238230453 500L 0 185.01288761769547z' fill='url(%26quot%3b%23SvgjsLinearGradient1038%26quot%3b)'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1036'%3e%3crect width='400' height='500' fill='white'%3e%3c/rect%3e%3c/mask%3e%3clinearGradient x1='0%25' y1='100%29' x2='100%28' y2='0%30' id='SvgjsLinearGradient1037'%3e%3cstop stop-color='rgba(109%2c 158%2c 147%2c 1)' offset='0'%3e%3c/stop%3e%3cstop stop-opacity='0' stop-color='rgba(109%2c 158%2c 147%2c 1)' offset='0.66'%3e%3c/stop%3e%3c/linearGradient%3e%3clinearGradient x1='100%25' y1='100%25' x2='0%25' y2='0%25' id='SvgjsLinearGradient1038'%3e%3cstop stop-color='rgba(109%2c 158%2c 147%2c 1)' offset='0'%3e%3c/stop%3e%3cstop stop-opacity='0' stop-color='rgba(109%2c 158%2c 147%2c 1)' offset='0.55'%3e%3c/stop%3e%3c/linearGradient%3e%3c/defs%3e%3c/svg%3e");
}

.metadataCard {
  @include info-card($darktablebg, $pineGreen);
  border: solid 1px $pineGreen;
  background: linear-gradient(240deg, rgba(27,34,54,1) 0%, rgba(27,34,54,1) 5%, rgba(61,84,79,0.3) 90%);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.dev/svgjs' width='2800' height='500' preserveAspectRatio='none' viewBox='0 0 550 400'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1071%26quot%3b)' fill='none'%3e%3cpath d='M37 400L437 0L887 0L487 400z' fill='url(%26quot%3b%23SvgjsLinearGradient1072%26quot%3b)'%3e%3c/path%3e%3cpath d='M496 400L96 0L-282 0L118 400z' fill='url(%26quot%3b%23SvgjsLinearGradient1073%26quot%3b)'%3e%3c/path%3e%3cpath d='M328.82182515528706 400L550 178.82182515528706L550 400z' fill='url(%26quot%3b%23SvgjsLinearGradient1072%26quot%3b)'%3e%3c/path%3e%3cpath d='M0 400L221.17817484471294 400L 0 178.82182515528706z' fill='url(%26quot%3b%23SvgjsLinearGradient1073%26quot%3b)'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1071'%3e%3crect width='550' height='400' fill='white'%3e%3c/rect%3e%3c/mask%3e%3clinearGradient x1='0%25' y1='100%25' x2='100%25' y2='0%25' id='SvgjsLinearGradient1072'%3e%3cstop stop-color='rgba(109%2c 158%2c 147%2c 1)' offset='0'%3e%3c/stop%3e%3cstop stop-opacity='0' stop-color='rgba(109%2c 158%2c 147%2c 1)' offset='0.66'%3e%3c/stop%3e%3c/linearGradient%3e%3clinearGradient x1='100%25' y1='100%25' x2='0%25' y2='0%25' id='SvgjsLinearGradient1073'%3e%3cstop stop-color='rgba(109%2c 158%2c 147%2c 1)' offset='0'%3e%3c/stop%3e%3cstop stop-opacity='0' stop-color='rgba(109%2c 158%2c 147%2c 1)' offset='0.66'%3e%3c/stop%3e%3c/linearGradient%3e%3c/defs%3e%3c/svg%3e");

}

.metadataCardToggleIcon {
  position: absolute;
  left: 50%;
  bottom: -1rem;
  font-size: 45px;
  color: $pineGreen;
  transition: transform 1.2s;
}

 .metadataCardToggleIcon:hover {
  cursor: pointer;
  transform: scale(1.1)
 }


.classMetadataCard {
  @include info-card(white, grey);
}

.classMetadataCardDark {
  @include info-card($darktablebg, $pineGreen);
  background: rgb(27,34,54);
  background: linear-gradient(270deg, rgba(27,34,54,1) 0%, rgba(27,34,54,1) 74%, rgba(61,84,79,0.3) 100%);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.dev/svgjs' width='2800' height='400' preserveAspectRatio='none' viewBox='0 0 550 650'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1036%26quot%3b)' fill='none'%3e%3cpath d='M18 500L518 0L728 0L228 500z' fill='url(%26quot%3b%23SvgjsLinearGradient1037%26quot%3b)'%3e%3c/path%3e%3cpath d='M393 500L-107 0L-327.5 0L172.5 500z' fill='url(%26quot%3b%23SvgjsLinearGradient1038%26quot%3b)'%3e%3c/path%3e%3cpath d='M85.01288761769547 500L400 185.01288761769547L400 500z' fill='url(%26quot%3b%23SvgjsLinearGradient1037%26quot%3b)'%3e%3c/path%3e%3cpath d='M0 500L314.98711238230453 500L 0 185.01288761769547z' fill='url(%26quot%3b%23SvgjsLinearGradient1038%26quot%3b)'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1036'%3e%3crect width='400' height='500' fill='white'%3e%3c/rect%3e%3c/mask%3e%3clinearGradient x1='0%25' y1='100%29' x2='100%28' y2='0%30' id='SvgjsLinearGradient1037'%3e%3cstop stop-color='rgba(109%2c 158%2c 147%2c 1)' offset='0'%3e%3c/stop%3e%3cstop stop-opacity='0' stop-color='rgba(109%2c 158%2c 147%2c 1)' offset='0.66'%3e%3c/stop%3e%3c/linearGradient%3e%3clinearGradient x1='100%25' y1='100%25' x2='0%25' y2='0%25' id='SvgjsLinearGradient1038'%3e%3cstop stop-color='rgba(109%2c 158%2c 147%2c 1)' offset='0'%3e%3c/stop%3e%3cstop stop-opacity='0' stop-color='rgba(109%2c 158%2c 147%2c 1)' offset='0.55'%3e%3c/stop%3e%3c/linearGradient%3e%3c/defs%3e%3c/svg%3e");
  padding: 2rem;
  border: solid 1px $pineGreen;

}

// FundHoldingsDashboardCard Component
@media screen and (min-width: 801px) {
  .scrollableContainer {
    overflow-x: hidden;
    overflow-y: auto;
  }
}

@media screen and (max-width: 800px) {
  .scrollableContainer {
    overflow-x: auto;
    overflow-y: auto;
  }
}

.mainFundTitleContainer {
  position: fixed;
  z-index: 100;
  background-color: $darktablebg;
  padding: .5rem 1rem;
  border-radius: 10px;
}

.mainFundTitleContainer p {
  padding-left: 1rem;
  color: $label;
  user-select: none;
  font-size: 18px;
}

.mainFundTitleContainer:hover {
  cursor: pointer;
}

.mainFundTitleContainer p:hover {
  cursor: pointer;
  color: $pineGreen;
  font-weight: 600;
}

.listIcon { 
  font-size: 40px;
  margin-left: 15rem;
  user-select: none;
}

.mainTitle {
  font-family: $inter;
  color: $pineGreen;
  margin: 0.5rem 0 1rem 0;
  font-weight: 300;
}

.mainFundTitle {
  font-family: $inter;
  color: $pineGreen;
  font-weight: 300;
  font-size: 30px;
  user-select: none;
}

.secondaryFundTitle {
  font-family: $inter;
  color: $label;
  font-weight: 300;
  font-size: 30px;
  user-select: none;
  margin-bottom: 2rem;
}
  

.mainDashboardTitle {
  color: #858585;
  margin: 2rem 0 3rem 2.5rem;
  font-weight: bold;
}

.subTitle {
  color: #b4a9a9;
  font-size: 14px;
  margin: 0.5rem 0;
  text-align: center;
  font-weight: 900;
}

.subTitle span {
  color: $pineGreen;
  font-weight: 900;
  margin: 1rem 0.3rem;
  font-size: 14px;
}

.cardTitle {
  font-family: $inter;
  color: $pineGreen;
  margin: 0.5rem 0 1rem 0;
  font-weight: 300;
  font-size: 22px;
}

.bookingPeriodtitle {
  color: $label;
  font-size: 15px;
}

.deleteButton {
  background-color: #6d9e93;
  padding: 5px 25px;
  text-decoration: none;
  color: white;
  border-radius: 34px;
  border-style: none;
}

.deleteButtonDark {
  font-family: $roboto;
  background-color: transparent;
  padding: 5px 25px;
  border-style: solid;
  border-width: 1px;
  border-color: #6d9e93;
  border-radius: 35px;
  color: white;
}


@mixin closeHistoryButtonMixin($shadowBg, $bgColor, $borderStyle, $borderColor) {
  background-color: $bgColor;
  padding: 0.7rem 1.5rem;
  text-decoration: none;
  color: $sand;
  border-radius: 8px;
  border-style: $borderStyle;
  border-color: $borderColor;
  border-width: 1px;
  font-size: 12px;
  margin: 0 1rem;
  // z-index: 5;
  box-shadow: 1px 7px 15px $shadowBg;
  // animation-name: infoCard;
  // position: relative;
  // animation-duration: 1.5s;
  // animation-timing-function: ease-in;
  transition-property: padding;
  transition-duration: 0.3s;
  transition-delay: 0.1s;
}

.closeHistoryButton {
  @include closeHistoryButtonMixin($label, $mossGreen, none, transparent);
}

.closeHistoryButtonDark {
  @include closeHistoryButtonMixin($pineGreen, $pineGreen, solid, $pineGreen);
}

.closeHistoryButtonDark:hover, .closeHistoryButton:hover {
  padding: 0.7rem 2.5rem;
}

.addContainer {
  text-align: right;
  color: $label;
  font-size: 13px;
}

.addContainer:hover {
  cursor: pointer;
}

.addIcon {
  background-color: transparent;
  padding: 0.4rem 0.7rem;
  text-decoration: none;
  color: $pineGreen;
  margin-left: 0.9rem;
  border-style: solid;
  border-color: $pineGreen;
  border-width: 0.2px;
  border-radius: 50%;
  font-weight: 900;
  display: inline-block;
  animation-duration: 2s;
  // animation-timing-function: ease-in;
  transition-property: padding;
  transition-duration: 0.3s;
  transition-delay: 0.1s;
}

.addIcon:hover {
  color: white;
  background-color: #738a84;
  padding: 0.5rem 1rem;
}

@mixin select-input($backgroundColor, $fontsColor) {
  background-color: $backgroundColor;
  color: $fontsColor;
  padding: 0.6rem 1rem;
  width: 100%;
  margin-bottom: 1rem;
  border-radius: 5px;
  font-weight: 300;
  font-size: 14px;
}

.selectInputDark {
  @include select-input($darktablebg, $labelDark);
}

.selectInputDark:hover {
  cursor: pointer
}

.selectInput {
  @include select-input(white, black);
}

.calendarLabelDark {
  color: #f0f0f0;
  margin-bottom: 1rem;
}

.calendarLabel {
  color: #404040;
  margin-bottom: 1rem;
}

.calendarInput {
  border-style: solid;
  border-color: #adadad;
  border-width: 2px;
}

.tableStrips {
  border-bottom: 2px solid #f7f7f7;
  text-align: center;
}

.tableStripsDark {
  font-family: $inter;
  border-bottom: 1px solid #535353;
  text-align: center;
  color: $labelDark;
}

.tableStripsDark td {
  font-family: $inter;
  font-size: 11px;
  vertical-align: middle;
  text-align: center;
}

.tableStripsDark:hover, .tableStrips:hover {
  color: $pineGreen;
  font-weight: 900;
}

// FundHoldingsDashboardCard Component


.disableFundHoldingsContainerButton {
  color: $pineGreen;
  font-size: 25px;
}

.disableFundHoldingsContainerButton:hover {
  cursor: pointer;
}

.holdingsHeader {
  position: sticky;
  top: 0;
  z-index: 200;
  background: white;
  height: 5rem;

  &.dark {
    background: $darktablebg;
  }
}

.searchInput {
  background: none;
  border: solid 1px $pineGreen;
  border-radius: 10px;
  color: $sand;
  font-weight: 100;
}

.thead {
  position: sticky;
  top: 0rem;
  background: $sand;
  z-index: 550;

  &.dark {
    background: $darktablebg;
  }
}

.endBalanceColumn {
  background-color: rgba($mossGreen, 0.15);
  border: 1px solid rgba($pineGreen, 0.2);
  font-weight: 600;
  color: $pineGreen;

  &.header {
    border-radius: 8px 8px 0 0;
    border-bottom: none;
  }

  &.footer {
    border-radius: 0 0 8px 8px;
    border-top: none;
  }

  &.middle {
    border-radius: 0 0 8px 8px;
    border-top: none;
    border-bottom: none;
  }

  &.dark {
    background-color: rgba($darkGreen, 0.4);
    border-color: rgba($pineGreen, 0.3);
    color: $mossGreen;
  }
}

.theader th:first-child {
  border-radius: 8px 0 0 8px;
}

.theader th:last-child {
  border-radius: 0 8px 8px 0;
}

.theader th {
  font-family: $inter;
  color: $pineGreen !important;
  text-align: center;
  font-size: 11px;
  font-weight: 100;
  user-select: none;
}

.theader th:hover {
  cursor: pointer;
}

.theadSortButton {
  border: solid 1px $pineGreen;
  background: transparent;
  color: $pineGreen;
  border-radius: 5px;
}

.tableDate {
  padding: 0.5rem;
  font-size: 10px;
}

.tableDate p {
  margin: 0;
  font-size: 11px;
  color: $pineGreen;
  font-weight: 900;
}

// Checkbox

.checkbox {
  display: inline-flex;
  cursor: pointer;
  position: relative;
  animation-name: createButtonAnimation;
  animation-duration: 1.5s;
  // animation-timing-function: ease-in;
}

.checkbox > span {
  color: #768594;
  padding: 0.5rem 1rem;
}

.checkbox > input {
  height: 25px;
  width: 25px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 1px solid #34495e;
  border-radius: 4px;
  outline: none;
  transition-duration: 0.5s;
  background-color: white;
  cursor: pointer;
  margin-top: 0.5rem;
}

.checkbox > input:checked {
  border: 2px solid #cdf3eb;
  background-color: #6d9e93;
}

.transferButton {
  text-decoration: none;
  color: #6d9e93;
}

.transferButton:hover {
  color: #6d9e93;
  font-weight: 900;
}

.editButton {
  padding: 4px 25px;
  color: white;
  border-radius: 35px;
  background-color: #6d9e93;
  text-decoration: none;
  font-weight: 100;
}

.editButtonDark {
  font-family: $roboto;
  padding: 4px 25px;
  color: white;
  border-radius: 8px;
  border-style: solid;
  border-width: 1px;
  text-decoration: none;
  border-color: #6d9e93;
  background-color: transparent;
  font-weight: 100;
}

.editButtonDark:hover, .deleteButtonDark:hover {
  color: #6d9e93;
}
.editButton:hover {
  color: white;
  font-weight: 900;
}

.icons {
  color: $pineGreen;
  font-size: 16px;
  display: inline;
}

.icons:hover {
  cursor: pointer;
  color: #738a84;
}

.tooltip {
  position: relative;
}

.tooltip .tooltiptext {
  visibility: hidden;
  background-color: #fcf9f9;
  color: #2f3e46;
  text-align: center;
  border-radius: 6px;
  padding: 0.2rem 1.5rem;
  font-size: 10px;
  font-weight: 200;
  opacity: 0.7;
  position: absolute;
  z-index: 1;
  top: -0.5rem;
  right: 0.5rem;
}

.tooltip .tooltiptextComparerTable {
  visibility: hidden;
  background-color: #222f3f;
  border: solid 1px $pineGreen;
  color: #fff;
  text-align: start;
  padding: 1rem 2rem;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  left: 5rem;
}

.tooltip .tooltiptextComparerTable::after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 50%;
  border-width: 5px;
  border-style: solid;
  border-color: $pineGreen transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip:hover .tooltiptextComparerTable {
  visibility: visible;
}

.emptyMessage {
  color: $label;
  font-size: 20px;
}

.headerTitle {
  font-family: $inter;
  text-align: center;
  color: $pineGreen;
  font-size: 15px;
  font-weight: 300;
}

.headerData {
  font-family: $robotoMono;
  text-align: center;
  font-size: 25px;
  margin: 1rem 0 0 0;
  color: $labelDark;
  font-weight: 300;
  animation-name: headerData;
  position: relative;
  animation-duration: .5s;
  // animation-timing-function: ease-in;
}

@keyframes headerData {
  from {
    right: 70px;
    opacity: 0;
  }
  to {
    right: 0px;
    opacity: 1;
  }
}

.cryptoIcons {
  filter: grayscale(75%);
  opacity: 1;
  margin: 0.3rem 0;
  display: inline-block;
  border-radius: 50%;
}

.tableCryptoName span {
  font-size: 11px;
  font-weight: 800;
  color: $label;
  margin: 0 0 0 0.5rem;
  display: inline-block;
}

.sidepopupContainer {
  position: fixed;
  background-color: $darktablebg;
  border: solid 1px $pineGreen;
  border-radius: 25px 0 0 25px;
  padding: 2rem;
  height: 100vh;
  width: 55%;
  top: 1rem;
  right: 0;
  z-index: 1000;
  animation-name: sidepopup;
  animation-duration: .5s;
  // animation-timing-function: ease-in;
  overflow-y: auto;
}

@keyframes sidepopup {
  0% {
      transform: translateX(100%); /* Start off-screen to the right */
  }
  100% {
      transform: translateX(0); /* End at the original position */
  }
}

@media only screen and (max-width: 1000px) {
  .sidepopupContainer {
    width: 100%;
  }
}

.formTitle {
  font-size: 25px;
  color: $pineGreen;
}

.formTitle span {
  color: $label
}

// Import Navs Modal
.textareaInput {
  border-radius: 10px;
  min-height: 30rem;
  padding: 0.5rem;
}

.submitButton {
  background-color: #6d9e93;
  border-radius: 12px;
  color: white;
  margin: 2rem auto;
}

// Fund Detail Options button
.dropdown {
  position: relative;
  margin-top: 3rem;
  float: right;
  width: 100%;
}

.dropdownContent {
  display: none;
  position: absolute;
  width: 100%;
  top: 2.5rem;
  background-color: $darktablebg;
  overflow-y: scroll;
  box-shadow: 10px 10px 45px rgba(0, 0, 0, 0.8);
  border: solid 1px $label;
  border-radius: 10px;
  z-index: 10;
  text-align: left;
  padding: 1rem .5rem;
  font-size: 13px;
  user-select: none;
  -webkit-user-select: none;
}

.dropdown button {
  border: solid 1px $pineGreen;
  background-color: transparent;
  border-radius: 8px;
  width: 100%;
  height: 2.5rem;
  font-size: 14px;
  color: $khaki;
  transition: padding-left 1s, background-color 1s;
  background: rgb(109,158,147);
  text-align: left;
  padding: 0.5rem 1rem;
}

.dropdown p {
  padding: .5rem 0;
  font-size: 11px;
  transition: padding-left 1s;
  color: $labelDark
}

.dropdownIcon {
  font-size: 15px;
  margin: 0 .5rem;
  color: $mossGreen;
}

.dropdown:hover .dropdownContent {
  display: block;
}

.dropdown button:hover {
  cursor: pointer;
  padding-left: 1.5rem;
  background-color: $pineGreen;
  color: #FFFFFF;
}

.dropdown p:hover {
  cursor: pointer;
  background-color: $pineGreen;
  color: #FFFFFF;
  padding-left: .2rem;
  border-radius: 6px;
}