@import "../styles/colors";
@import "../styles/typography";

.toggleIcon {
  color: $pineGreen;
  font-size: 25px;
  text-align: center;
  display: inline;
}

.title {
    display: inline;
    margin: 0 1rem;
    font-size: 22px;
    font-weight: 300;
    font-family: $inter;
    color: $pineGreen;
}

.classTitle {
  display: inline;
  margin: 0 1rem;
  font-size: 22px;
  font-weight: 300;
  font-family: $inter;
  color: $mossGreen;
  text-decoration: underline;
}

.toggleIcon:hover {
  cursor: pointer;
}

.collapsibleContainer {
  width: 100%;
  margin: 0 auto;
  padding: 2rem;
}

.hideShowMessage {
  color: $pineGreen;
  // border-bottom-style: ridge;
  // border-bottom-color: $label;
  // border-bottom-width: .1px;
  text-align: left;
  padding: 0.5rem 0;
  margin-left: 2rem;
}

@mixin info-card($backgroundColor, $boxShadowColor) {
  background-color: $backgroundColor;
  border-radius: 15px;
  border: solid 1px $mossGreen;
  box-shadow: 1px 11px 25px $boxShadowColor;
  margin: 2rem auto;
  padding: 2rem 1rem;
  // animation-name: infoCard;
  // position: relative;
  // animation-duration: 1.5s;
  // animation-timing-function: ease-in;
}

@keyframes infoCard {
  from {
    right: 70px;
    opacity: 0;
  }
  to {
    right: 0px;
    opacity: 1;
  }
}

.cardModel {
  @include info-card(white, grey);
}

.cardModelDark {
  @include info-card($darktablebg, $pineGreen);
}

.bookingPeriodTag {
  color: $label;
  font-style: italic;
  float: right;
  margin: 0 1rem;
  border: solid 1px $label;
  padding: .3rem 1rem;
  font-size: 12px;
  border-radius: 35px;
}