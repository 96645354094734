@import "./colors";
@import "./typography";

// DarkMode
.tableStripsDark {
  border-bottom: 1px solid #535353;
  color: $labelDark;
  text-align: center;
  font-weight: 900;
  font-size: 10px;
}

.tableStripsDark:hover, .tableStrips:hover {
  color: $pineGreen;
  font-weight: 900;
}

.theaderDark th {
  color: $pineGreen !important;
  text-align: center;
  font-family: $inter;
}

.editButtonDark {
  padding: 7px 25px;
  color: white;
  border-radius: 10px;
  text-decoration: none;
  border: none;
  background-color: $pineGreen;
  font-size: 11px;
  font-family: $roboto;
}

.editButton:hover, .editButtonDark:hover, .deleteButtonDark:hover, .deleteButton:hover {
  background-color: #a5c6ac;
  color: #f0f0f0;
  font-weight: 600;
  border-style: none;
}

.deleteButtonDark {
  border-radius: 35px;
  padding: 7px 25px;
  color: white;
  border-style: solid;
  border-width: 1.5px;
  border-color: $pineGreen;
  background-color: transparent;
}

// End of DarkMode

@mixin buttons(){
  background-color: transparent;
  padding: 5px 25px;
  text-decoration: none;
  color: $labelDark;
  border-radius: 10px;
  border-style: solid;
  border-color: $pineGreen;
  font-family: $roboto;
  border-width: 0.2px;
  font-size: 12px;
  margin: 0 1rem;
  z-index: 5;
  // box-shadow: 1px 0px 8px $pineGreen;
  // animation-name: infoCard;
  // position: relative;
  // animation-duration: 1.5s;
  // animation-timing-function: ease-in;
  transition-property: padding;
  transition-duration: 0.3s;
  transition-delay: 0.1s;
}

.editButton {
  @include buttons();
}

.editButtonDisabled {
  @include buttons();
  border-color: #535353
}

.deleteButton {
  @include buttons();
}

.theader th {
  color: $pineGreen !important;
  text-align: center;
}

.tableStrips {
  border-bottom: 2px solid #f7f7f7;
  text-align: center;
}

// Only for AllListings
.noListing {
  text-align: center;
  font-weight: bold;
  font-size: 25px;
  color: #5c8f84;
  margin: 2rem 0;
}

.noListingDark {
  text-align: center;
  font-weight: bold;
  font-size: 25px;
  color: white;
  margin: 6rem 0;
}

.backLink {
  font-size: 20px;
  text-decoration: none;
  color: #6d9e93;
  font-weight: 900;
  cursor: pointer;
}

.backLink:hover {
  color: #6d9e93;
}

// Tables
th {
  font-family: $inter;
  font-size: 12px;
  // animation-name: tableHead;
  // position: relative;
  // animation-duration: 1.5s;
  // animation-timing-function: ease-in;
  font-weight: 100;
}

@keyframes tableHead {
  from {
    bottom: 30px;
  }
  to {
    bottom: 0px;
  }
}

tr {
  font-family: $inter;
  font-size: 10px;
  // animation-name: tableCells;
  // position: relative;
  // animation-duration: 1.5s;
  // animation-timing-function: ease-in;
  // vertical-align: middle;
}

td {
  font-family: $inter;
  font-size: 12px;
  font-weight: 100;
}

td:hover {
  cursor: context-menu;
}

@keyframes tableCells {
  from {
    right: 70px;
    opacity: 0;
  }
  to {
    right: 0px;
    opacity: 1;
  }
}

.emptyIconDark {
  font-size: 80px;
  color: #6d9e93;
  margin: 4rem 0 1rem 0;
}

// Icons and Tooltip for Edit and Delete in table
.icons {
  color: $pineGreen;
  font-size: 16px;
  display: inline;
}

.icons:hover {
  cursor: pointer;
  color: #738a84;
}

.tooltip {
  position: relative;
}

.tooltip .tooltiptext {
  visibility: hidden;
  background-color: #fcf9f9;
  color: #2f3e46;
  text-align: center;
  border-radius: 6px;
  padding: 0.2rem 1.5rem;
  font-size: 10px;
  font-weight: 200;
  opacity: 0.7;
  position: absolute;
  z-index: 1;
  top: -0.5rem;
  right: 0.5rem;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}