@import "../../../styles/Forms.scss";

.backLink {
    text-align: center;
    color: $pineGreen;
    width: 3rem;
    margin: auto;
}

.backLink p {
    color: $pineGreen;
    font-size: 12px;
}

.backLink:hover {
    cursor: pointer;
}