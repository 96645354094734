@import '../../styles/AllEntities.scss';
@import '../../styles/Dashboards.scss';
@import '../../styles/Forms.scss';

.cardTableDark {
    text-align: start;
    z-index: 5;
    box-shadow: 1px 0px 25px $pineGreen;
    padding: 1rem;
}

.metadata h4 {
    text-align: start;
    font-size: 0.9rem;
    font-weight: 500;
    color: $pineGreen;
    margin-bottom: 0.5rem;
}

.metadata h5 {
    text-align: start;
    font-size: 1rem;
    font-weight: 500;
    color: white;
    margin-bottom: 0;
}

.subTitle {
    font-size: 1.2rem;
    font-weight: 500;
    color: $pineGreen;
    margin-bottom: 0;
}